/*
|--------------------------------------------------------------------------
| Vendor Imports
|--------------------------------------------------------------------------
*/
$grid-float-breakpoint: 768px !default;

@import "vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
// Other Imports
@import "elements/fonts";
@import "elements/marcosanim";
@import "vendor/bower_components/font-awesome/scss/font-awesome.scss";
