.mg-team {
  padding: 70px 0 80px;
}

.mg-marcosanim {
  position: relative;
  margin-bottom: 30px;
  .mg-marcosanim-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: #fff;
    opacity: 0;
    -webkit-transition: opacity 0.8s;
    transition: opacity 0.8s;
    // p {
    //   display: none;
    // }
    h3 {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 8px;
      color: #fff;
    }
    h3:after {
      content: '';
      width: 25px;
      height: 2px;
      background-color: #b6bd9c;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transition: width 0.5s;
      transition: width 0.5s;
    }
    strong {
      display: block;
      font-style: italic;
      margin-bottom: 20px;
    }
  }

  .mg-marcosanim-overlayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.9);
    opacity: 0;
    -webkit-transition: opacity 0.8s;
    transition: opacity 0.8s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    cursor: pointer;
  }

  .overlay-visible {
    opacity: 1;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;

  }
.info-visible {
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .info-visible {
    h3:after {
      width: 100%;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
    }
  }
  .mg-marcosanim-social {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      float: left;
      a {
        display: block;
        text-align: center;
        width: 40px;
        font-size: 16px;
        line-height: 40px;
        background-color: rgba(0, 0, 0, 0.2);
        margin-right: 5px;
        color: #fff;
        -webkit-transition: background-color 0.5s;
        transition: background-color 0.5s;
      }
      a:hover {
        background-color: #b6bd9c;
      }
    }
  }

  &:hover {
    .mg-marcosanim-overlayer {
      opacity: 1;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
    .mg-marcosanim-info {
      opacity: 1;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
    }
    .mg-marcosanim-info {
      h3:after {
        width: 100%;
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
      }
    }
  }
}







