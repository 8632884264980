/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Playfair+Display:400,400i,700,700i,900,900i';
@import 'https://fonts.googleapis.com/css?family=PT+Sans';
@import 'https://fonts.googleapis.com/css?family=EB+Garamond';

$font-open: 'Open Sans', sans-serif;
$font-play: 'Playfair Display', serif;
$font-pt: 'PT Sans', sans-serif;
$font-gar: 'EB Garamond', serif;
$font-gotlight: 'Gotham-Light', sans-serif;
$font-gotbook: 'Gotham-Book', sans-serif;